.slider-banner-container {
  width: 85%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3% 0 7% 0;
}

.banner-slider-title {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-slider-title h2 {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.banner-items-container {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banners-container {
  width: 87%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 0px 0px;
  scrollbar-width: none;
  overflow-x: auto;
  scroll-behavior: smooth;
  transition: scroll 0.5s ease;
}

.section-item {
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 24px;
  transition: 1s linear;
}

.insurance-box {
  width: 40%;
  height: 75%;
  max-width: 550px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5%;
  background-color: rgba(0, 0, 0, 0.705);
  border: 2px solid rgb(0, 0, 0);
  backdrop-filter: blur(10px);
  border-radius: 16px;
}

.financy-box {
  width: 35%;
  height: 80%;
  max-width: 550px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5%;
  background-color: rgba(201, 0, 0, 0.842);
  border: 2px solid red;
  backdrop-filter: blur(10px);
  border-radius: 16px;
}

.free-inspection-box {
  width: 40%;
  height: 80%;
  max-width: 550px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5%;
  background-color: #ffe600d0;
  border: 2px solid #fffb00;
  backdrop-filter: blur(10px);
  border-radius: 16px;
}

.emergency-services-box {
  width: 40%;
  height: 75%;
  max-width: 550px;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5%;
  background-color: #ffe600d0;
  border: 2px solid #fffb00;
  backdrop-filter: blur(10px);
  border-radius: 16px;
}

.banner-info {
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.general-title-item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.icon-check {
  width: 30px;
}

.icon-insurance {
  width: 35px;
}

.icon-damage {
  width: 25px;
}

.free-img-banner {
  width: 30px;
}

.insurance-box hr {
  width: 100%;
  height: 2px;
  border: none;
  border-radius: 3px;
  background-color: black;
}

.financy-box hr {
  width: 100%;
  height: 2px;
  border: none;
  border-radius: 3px;
  background-color: red;
}

.free-inspection-box hr {
  width: 100%;
  height: 2px;
  border: none;
  border-radius: 3px;
  background-color: #fffb00;
}

.emergency-services-box hr {
  width: 100%;
  height: 2px;
  border: none;
  border-radius: 3px;
  background-color: #fffb00;
}

.emergency-services-box p {
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}


.emergency-services-box h3 {
  text-align: start;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.4rem;
  font-weight: bold;
  color: black;
}

.insurance-box p {
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.financy-box p {
  color: white;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.insurance-box h3,
.financy-box h3 {
  width: 100%;
  color: white;
  text-align: start;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.4rem;
  font-weight: bold;
}

.free-inspection-box p {
  color: black;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}



.free-inspection-box h3 {
  width: 100%;
  color: black;
  text-align: start;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 1px;
  font-size: 1.4rem;
  font-weight: bold;
}


.black-button-financy {
  background-color: black;
  color: white;
  padding: 17px 20px;
  font-size: 0.9rem;
  text-transform: uppercase;
  border-radius: 15px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.black-button-financy:hover {
  background-color: white;
  color: black;
}

.white-banner-btn {
  background-color: #ffffff;
  color: rgb(214, 0, 0);
  padding: 15px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.white-banner-btn:hover {
  background-color: black;
  color: white;
}

.black-button-banner {
  background-color: black;
  color: white;
  padding: 15px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.black-button-banner:hover {
  background-color: white;
  color: #000000;
  transform: scale(1.05);
}

.item-service-img {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  object-fit: cover;
  border-radius: 24px;
}

.banner-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

.banner-button-container button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: rgb(230, 0, 0);
  backdrop-filter: blur(10px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-button-container button img {
  width: 14px;
}

.banner-button-container button:hover {
  background-color: rgb(255, 0, 0);
}

.banner-arrow-left {
  transform: rotate(180deg);
}

@media (max-width: 1024px) {
  .slider-banner-container {
    width: 85%;
    height: 500px;
  }

  .banners-container {
    width: 100%;
    height: 100%;
    gap: 40px;
  }

  .financy-banner-container {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    border-radius: 24px;
  }

  .financy-title-parraf {
    width: 40%;
    min-width: 340px;
    min-height: 380px;
    gap: 50px;
  }

  .banner-info {
    width: 85%;
    gap: 15px;
  }

  .financy-title-parraf img {
    width: 30px;
    height: 30px;
  }

  .financy-title-parraf h3 {
    font-size: 1.5rem;
    letter-spacing: 2px;
  }

  .item-service-img {
    width: 100%;
    height: 100%;
  }

  .banner-button-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .slider-banner-container {
    width: 100%;
    height: 70vh;
    margin: 3% 0 20% 0;
  }

  .banner-slider-title {
    width: 100%;
    height: 10%;
  }

  .banner-slider-title h2 {
    font-size: 1.6rem;
  }

  .banner-items-container {
    width: 100%;
    height: 90%;
  }

  .item-service-img {
    width: 100%;
    height: 40%;
    position: relative;
    object-fit: cover;
    border-radius: 24px 24px 0px 0px;
    object-position: right;
  }


  .section-item {
    min-width: 70%;
    height: 100%;
    flex-direction: column-reverse;
    justify-content: flex-start;
  }

  .emergency-services-box {
    width: 100%;
    height: 60%;
    margin-left: 0%;
    border: none;
    border-radius: 0px 0px 16px 16px;
  }

  .banner-info {
    width: 85%;
  }

  .insurance-box {
    width: 100%;
    height: 60%;
    margin-left: 0%;
    background-color: rgba(0, 0, 0, 0.829);
    border: none;
    border-radius: 0px 0px 16px 16px;
  }

  .financy-box {
    width: 100%;
    height: 60%;
    margin-left: 0%;
    border: none;
    border-radius: 0px 0px 16px 16px;
  }

  .free-inspection-box {
    width: 100%;
    height: 60%;
    margin-left: 0%;
    border: none;
    border-radius: 0px 0px 16px 16px;
  }

  .banners-container {
    gap: 25px;
  }

  .banners-container .section-item:first-child{
    margin-left: 7%;
  }

  .banners-container .section-item:last-child{
    margin-right: 7%;
  }
  
  .emergency-services-box p {
    font-size: 1.2rem;
  }
  
  .emergency-services-box h3 {
    font-size: 1.3rem;
  }

  .insurance-box p {
    font-size: 1.1rem;
  }
  
  .financy-box p {
    font-size: 1.1rem;
  }
  
  .insurance-box h3,
  .financy-box h3 {
    font-size: 1.2rem;
  }
}
