.cont-header {
  max-width: 2750px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  padding: 1% 0 2% 0;
  animation: foo 1s ease-in-out;
  
}

@keyframes foo {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

.logo-home {
  width: 80%;
  margin: 20px 20%;
  max-width: 200px;
}

.cont-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  /* min-width: 500px; */
  max-width: 450px;
  height: 35px;
  margin-right: 4%;
}

.bar-nav {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.774);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  backdrop-filter: blur(10px);
  border: 2px solid black;
}

.bar-nav li {
  height: 80%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bar-nav .acces-page {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.bar-nav li:hover {
  border-radius: 9px;
  background-color: red;

  & .acces-page {
    font-weight: bold;
  }
}

.responsive-menu {
  display: none;
}

@media (max-width: 1024px) {

  .logo-home {
    width: 80%;
    margin: 20px 15%;
    max-width: 180px;
  }

  .cont-nav {
    display: none;
  }

  .responsive-menu {
    display: block;
    width: 5.5vh;
    height: 5.5vh;
    margin-right: 70px;
    cursor: pointer;
  }

  .container-nav-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgb(255, 0, 0);
  }

  .container-nav-bar img{
    width: 50%;
  }

  .menu-responsive-screen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color:rgba(255, 0, 0, 0.87);
    z-index: 3;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(10px);
  }

  .header-menu-responsive{
    width: 85%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-menu-responsive img{
    width: 30%;
  }

  .header-menu-responsive button{
    width:50px;
    height: 50px;
    border-radius:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform:uppercase;
    color: red;
    font-weight:bold;
    font-size:1.5rem;
    border: none;
    cursor: pointer;
  }

  .menu-responsive-nav ul{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap: 60px;
  }

  .menu-responsive-nav ul li .link-responsive{
    text-decoration: none;
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .container-socialbtns{
    margin-bottom:50px;
  }
}


@media (max-width: 600px){
  .logo-home {
    width: 70%;
    margin-left: 20px;
  }
  .responsive-menu {
    margin-right: 30px;
  }

  .header-menu-responsive img{
    width: 45%;
  }

  .header-menu-responsive button{
    width:40px;
    height: 40px;
  }

  .menu-responsive-nav ul li .link-responsive{
    font-size: 1.7rem;
  }

  .menu-responsive-nav ul{
    gap: 40px;
  }
}