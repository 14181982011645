.section-recomendations {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider-comments {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.comments-all {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-start;
  overflow-x: hidden;
  gap: 50px;
  scroll-behavior: smooth;
}

.comments-all span {
  display: none;
}

.item-comments {
  min-width: 55%;
  height: 75%;
  background-color: red;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  text-decoration: none;
}

.box-info-comments {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.comillas {
  width: 40px;
}

.box-info-comments .comment-text {
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.3;
  letter-spacing: 0.5px;
}

.box-user-comments {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.img-perfil-user {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;
}

.name-user p:first-child {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 7px;
}

.name-user p:last-child {
  font-size: 0.8rem;
}

.btns-comments {
  width: 30%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.btns-comments button {
  width: 50px;
  height: 50px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.616);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btns-comments button img {
  width: 13px;
}

.btns-comments button:first-child img {
  transform: rotate(180deg);
}

.btns-comments button:hover {
  background-color: rgb(255, 0, 0);
}

.info-section-recomendations {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cont-info-comments {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cont-info-comments h4 {
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 7px 10px;
  background-color: #ff0000;
  display: inline-block;
}

.cont-info-comments h2 {
  color: white;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  letter-spacing: 2px;
}

.cont-info-comments h2 span {
  color: #ff0000;
}

.cont-info-comments p {
  color: white;
  font-size: 1rem;
  line-height: 1.3;
  width: 85%;
  margin-top: 25px;
  letter-spacing: 1px;
}

.bg-section-comments {
  width: 65%;
  height: 100%;
  object-fit: cover;
  background-color: black;
  position: absolute;
  right: 0px;
  z-index: -1;
  border-radius: 0px 0px 0px 30px;
}

@media (max-width: 1024px) {
  .comments-all span {
    display: block;
    width: 10%;
  }

  .section-recomendations {
    flex-direction: column-reverse;
  }

  .bg-section-comments {
    width: 100%;
    border-radius: 0px 0px 50px 50px;
  }

  .slider-comments {
    width: 100%;
    height: 55%;
    align-items: center;
    justify-content: flex-start;
  }

  .info-section-recomendations {
    width: 100%;
    height: 45%;
  }

  .cont-info-comments {
    width: 70%;
    align-items: center;
  }

  .cont-info-comments p {
    font-size: 2.3vh;
    width: 100%;
    text-align: center;
  }

  .cont-info-comments h2 {
    font-size: 5vh;
  }

  .comments-all {
    height: 70%;
    align-items: flex-start;
  }

  .comments-all::-webkit-scrollbar {
    height: 0px;
  }

  .comments-all::-webkit-scrollbar-track {
    background: transparent;
  }

  .comments-all::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .item-comments {
    min-width: 40%;
    height: 100%;
  }

  .btns-comments {
    width: 100%;
    height: 30%;
  }

  .comillas {
    width: 25px;
  }

  .box-info-comments {
    width: 85%;
    height: 85%;
  }

  .box-info-comments .comment-text {
    width: 100%;
    font-size: 2vh;
    overflow-y: auto;
    max-height: 320px;
  }

  .img-perfil-user {
    width: 40px;
    height: 40px;
  }

  .name-user p:first-child {
    font-size: 0.8rem;
  }

  .name-user p:last-child {
    font-size: 0.7rem;
  }
}

@media (max-width: 600px) {
  .slider-comments {
    width: 100%;
    height: 60%;
    align-items: center;
    justify-content: flex-start;
  }

  .info-section-recomendations {
    width: 100%;
    height: 40%;
  }

  .item-comments {
    min-width: 70%;
  }

  .comments-all {
    height: 70%;
    overflow-x: auto;
  }

  .box-info-comments .comment-text {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }

  .cont-info-comments {
    width: 80%;
  }

  .cont-info-comments p {
    font-size: 0.9rem;
  }

  .cont-info-comments h2 {
    font-size: 1.7rem;
  }
}
