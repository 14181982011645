.free-inspection-container {
  width: 70%;
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -40px;
}

.first-column-contact {
  width: 50%;
  height: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 0 0 0 24px;
  background-color: black;
}

.free-title-box {
  width: 380px;
  transform: rotate(-5deg);
  position: absolute;
  top: -60px;
  left: -80px;
}

.text-castor-container {
  height: 65%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.text-explication {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.3;
  color: white;
}


.castor-img {
  width: 260px;
  transform: scaleX(-1);
  margin-bottom: -40px;
}

.second-column-bg {
  width: 50%;
  height: 60%;
  background-color: black;
  border-radius: 0 24px 24px 0;
}

.second-column-contact {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  background-color: #ffdb1f;
  border: 2px solid black;
  position: relative;
}

.tornillos-right {
  width: 20px;
  height: 90%;
  right: 10px;
  position: absolute;
}

.tornillos-left {
  width: 20px;
  height: 90%;
  left: 10px;
  position: absolute;
}

.form-container-free-inspection {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  position: relative;
}

.form-container-free-inspection label {
  width: 100%;
  height: 50px;
  outline: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 50px;
  position: relative;
}

.form-container-free-inspection input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border: none;
  text-align: center;
}

.form-container-free-inspection input::placeholder {
  text-align: center;
}

.form-container-free-inspection button {
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 10px 15px;
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.863);
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.success-message-free-inspection {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.906);
  border-radius: 24px;
  padding: 10px;
  position: absolute;
  gap: 20px;
}

.success-message-free-inspection img {
  width: 70px;
}

.icon-form {
  width: 40px;
  height: 40px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  position: absolute;
}

.icon-form img {
  width: 20px;
}

.select-box {
  width: 90%;
  display: flex;
  text-align: center;
  margin: 0 auto;
  border: none;
  outline: none;
  color: rgb(91, 91, 91);
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.select-box option {
  background-color: #ffdb1f;
  color: rgb(0, 0, 0);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

/* Media Queries */
@media (max-width: 1024px) {
  .free-inspection-container {
    width: 80%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 25% 0px 5% 0px;
  }

  .first-column-contact {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    border-radius: 24px 24px 0 0;
    border: 2px solid;
    background-color: black;
    gap: 50px;
  }

  .free-title-box {
    width: 280px;
    transform: rotate(3.6deg);
    position: absolute;
    top: -65px;
    left: 33%;
  }

  .text-castor-container {
    height: 50%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .text-explication {
    width: 100%;
    font-size: 1.4rem;
    line-height: 1.3;
    color: white;
  }

  .castor-img {
    width: 200px;
    transform: scaleX(1);
    margin-bottom: 2px;
    z-index: 1;
  }

  .second-column-bg {
    width: 100%;
    height: 75%;
    background-color: black;
    border-radius: 0 0 24px 24px;
    border: 2px solid;
  }

  .second-column-contact {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    background-color: #ffdb1f;
    border: 0px solid black;
    position: relative;
  }

  .tornillos-right {
    width: 20px;
    height: 90%;
    right: 10px;
    position: absolute;
  }

  .tornillos-left {
    width: 20px;
    height: 90%;
    left: 10px;
    position: absolute;
  }
}

@media (max-width: 600px) {
  .free-inspection-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35% 0px 5% 0px;
  }

  .first-column-contact {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: static;
    border-radius: 24px 24px 0 0;
    background-color: black;
   
    gap: 120px;
  }

  .free-title-box {
    width: 230px;
    transform: rotate(3.8deg);
    position: static;
   
    margin-top: -60px;
  }

  .text-castor-container {
    height: 65%;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
   
    margin-top: -60px;
    gap: 20px;
  }

  .text-explication {
    width: 90%;
    font-size: 1.5rem;
    line-height: 1.3;
    color: white;
    text-align: center;
  }
  
  .castor-img {
    width: 160px;
    transform: scaleX(1);
    margin-bottom: -32px;
  }
  
  .second-column-bg {
    width: 100%;
    height: 60%;
    background-color: black;
    border-radius: 0 0 24px 24px;
  }
  
  .second-column-contact {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    background-color: #ffdb1f;
    border: 0px solid black;
    position: relative;
  }

  .tornillos-right {
    width: 20px;
    height: 90%;
    right: 10px;
  }

  .tornillos-left {
    width: 20px;
    height: 90%;
    left: 10px;
  }
}


@media (max-width: 400px) {
  .free-inspection-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35% 0px 5% 0px;
  }

  .first-column-contact {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: static;
    border-radius: 24px 24px 0 0;
    background-color: black;
   
    gap: 100px;
  }

  .free-title-box {
    width: 230px;
    transform: rotate(3.8deg);
    position: static;
    
    margin-top: -70px;
  }

  .text-castor-container {
    height: 65%;
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  
    margin-top: -60px;
    gap: 20px;
  }

  .text-explication {
    width: 90%;
    font-size: 1.2rem;
    line-height: 1.3;
    color: white;
    text-align: center;
  }
  
  .castor-img {
    width: 130px;
    transform: scaleX(1);
    margin-bottom: -40px;
  }
  
  .second-column-bg {
    width: 100%;
    height: 60%;
    background-color: black;
    border-radius: 0 0 24px 24px;
  }
  
  .second-column-contact {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    background-color: #ffdb1f;
    border: 0px solid black;
    position: relative;
  }

  .tornillos-right {
    width: 20px;
    height: 90%;
    right: 10px;
  }

  .tornillos-left {
    width: 20px;
    height: 90%;
    left: 10px;
  }
}
