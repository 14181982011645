.arrow-up-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  background-color: red;
  border: 2px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: all 2s ease-in;
  animation: arrow 1s ease-in-out;
  z-index: 2;
}

.arrow-up-button:hover{
  transform: scale(1.3);
  transition: all 0.2s ease-in-out;
}

.arrow-up-button.visible {
  display: inline-block;
}

.arrow-up-button.hidden {
  display: none;
}

.arrowup-img {
  transform: rotate(270deg);
}

