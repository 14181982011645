.section-story{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    padding: 60px 0px;
}

.section-story h4{
    background-color:red;
    color: white;
    font-size:1.1rem;
    padding: 10px 10px;
    text-transform:uppercase;
    margin-bottom: 20px;
}


.section-story h2{
    font-size: 3.0rem;
    color: black;
    text-transform:uppercase;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
}

.section-story h2 span{
    color: red;
}

.cont-slider-our-section{
    width: 80%;
    height:65vh;
    border-radius:20px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.title-slider-story{
    font-size: 3.0rem;
    text-transform: uppercase;
    font-weight:bold;
    color: white;
}

.slider-story-area{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: hidden;
    z-index: -1;
    scroll-behavior: smooth;
}

.slider-story-area img{
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.section-btn-slider-story{
    position: absolute;
    bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.section-btn-slider-story button{
    width:50px;
    height: 50px;
    border: 2px solid white;
    background-color:rgba(0, 0, 0, 0.473);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-btn-slider-story button img{
    width:13px;
}

.section-btn-slider-story button:first-child img{
    transform: rotate(180deg);
}

.section-btn-slider-story button:hover{
    background-color:rgb(255, 0, 0);
}

.section-story h3{
    font-size:2.0rem;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: bold;
}

.section-story h3 span{
    color: red;
}

.section-story .info-our-story{
    width: 50%;
    text-align: center;
    margin-bottom: 50px;
    font-size: 1.0rem;
    line-height: 1.3;
}

.benefits-section{
    width: 80%;
    height:200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.benefits-section .item-benefits{
    width: 30%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.item-benefits img{
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.color-img-benefits{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.747);
    border-radius: 25px;
    z-index: -1;
}

.item-benefits:hover{
    & .color-img-benefits{
        background-color:rgba(218, 0, 0, 0.836);
    }
}

.info-benefits{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.info-benefits p{
    font-size: 3.0rem;
    margin-right: 5px;
    font-weight: bold;
}

.info-benefits span{
    font-size: 2.0rem;
    font-weight: bold;
}

.name-benefits{
    font-size: 1.3rem;
    color: white;
    margin-top: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

@media (max-width: 1024px){
    .section-story h2{
        width: 50%;
        font-size: 2.5rem;
    }

    .title-slider-story{
        width: 55%;
        text-align: center;
        font-size: 2.5rem;
    }

    .cont-slider-our-section{
        width: 85%;
    }

    .benefits-section{
        width: 85%;
        height: 300px;
    }

    .name-benefits{
        width: 70%;
        text-align: center;
        line-height: 1.3;
        font-size: 1.3rem;
        letter-spacing: 2px;
    }

    .section-story .info-our-story{
        width: 70%;
    }
}

@media (max-width: 600px){
    .section-story h2{
        width: 75%;
        font-size: 2.3rem;
    }

    .title-slider-story{
        width: 75%;
        text-align: center;
        font-size: 2.3rem;
    }

    .benefits-section{
        flex-direction: column;
        height: 450px;
    }

    .benefits-section .item-benefits{
        width: 100%;
        height:30%;
        flex-direction: row;  
        gap: 10px;      
    }

    .info-benefits p{
        font-size: 2.5rem;
    }
    
    .info-benefits span{
        font-size: 2.0rem;
        font-weight: bold;
    }
    
    .name-benefits{
        font-size: 1.2rem;
        margin-top: 0px;
        width: 35%;
    }

    .section-story h3{
        font-size:1.6rem;
    }

    .section-story .info-our-story{
        width: 80%;
    }
    
}