@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: Inter !important;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px; 
}

body::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);        
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(255, 0, 0);    
  border-radius: 20px;
}



