.cont-alert-mascot{
    width:35%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    background-color: rgb(255, 238, 0);
    border-radius: 20px 0px 0px 20px;
    padding: 0px 20px;
    position: relative;
    animation: mascot 3s ease-in-out;
    text-align: right;
}

@keyframes mascot {
    0% {
        right: -500px;
    }
    20% {
        right: -500px;
    }
    40% {
        right: -500px;
    }
    60% {
        right: -500px;
    }
    80% {
        right: -500px;
    }
    100% {
        right: 0px;
    }
}

.icon-castor{
    position: absolute;
    width: 190px;
    top: -133px;
    right: 0px;
}

.cont-icon-alert{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color:white;
    outline: 3px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    top: -15px;
}



.cont-icon-alert img{
    width:20px;
    animation: dimentionmascot 1s infinite ease-in-out;
}

@keyframes dimentionmascot {
    0% {
        transform: rotate(25deg);
    }
    20% {
        transform: rotate(-25deg);
    }
    100% {
        transform: rotate(25deg);
    }
}

.cont-alert-mascot h3{
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 20px;
}

.cont-alert-mascot p{
    font-size: 0.9rem;
    width: 95%;
    text-align: right;
    margin-top:10px;
    font-weight: bold;
    margin-bottom: 20px;
}


@media (max-width: 1024px){
    .cont-alert-mascot{
        width:45%;
    }

    .icon-castor{
        width: 160px;
        top: -111px;
        right: 0px;
    }

    .cont-alert-mascot h3{
        font-size: 1.1rem;
    }
}

@media (max-width: 600px){
    .cont-alert-mascot{
        width:30%;
    }

    .icon-castor{
        width: 130px;
        top: -91px;
    }

    .cont-alert-mascot h3{
        font-size: 1.0rem;
        margin-top: 13px;
    }
    
    .cont-alert-mascot p{
        width: 100%;
        font-size: 0.7rem;
        margin-bottom: 13px;
    }

    .cont-icon-alert{
        width: 25px;
        height: 25px;
        left: -10px;
        top: -10px;
        outline: 2px solid black;
    }
    
    .cont-icon-alert img{
        width:15px;
    }
}