.insurance-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  /* border: 1px solid brown; */
}









.contact-divider {
  margin: 3%;
  flex: 1;
  font-size: 4rem;
  font-weight: bold;
}

.contacting-container {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.819);
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-parraf-contact-form {
  width: 60%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}

.title-parraf-contact-form h3 {
  max-width: 600px;
  width: 80%;
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
}

.title-parraf-contact-form p {
  width: 65%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.contact-form-container {
  width: 50%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  gap: 30px;
  padding: 2% 0%;
}

.contact-form-container label {
  width: 80%;
  min-height: 60px;
  height: 70px;
  outline: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 50px;
  position: relative;
}

.contact-form-container input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 1rem;
}

.contact-form-container input::placeholder {
  text-align: center;
}

.box-icon-contact-form {
  width: 60px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  position: absolute;
}

.box-icon-contact-form img {
  width: 26px;
}

.contact-form-container button {
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 10px 30px;
  background-color: red;
  color: white;
  border-radius: 16px;
  cursor: pointer;
  border: 2px solid white;
  font-size: 1.1rem;
  font-weight: bold;
}

.contact-form-container button:hover {
  background-color: white;
  color: red;
  cursor: pointer;
}

.contact-form-bg {
  width: 100%;
  height: 700px;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

@media (max-width: 1024px) {
  .insurance-contact {
    width: 100%;
    margin-top: 5%;
  }





  .contact-divider {
    margin: 3%;
    flex: 1;
    font-size: 3rem;
  }

  .contacting-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0%;
  }

  .contact-container {
    width: 100%;
    height: 760px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.4);
    margin: 0;
  }

  .title-parraf-contact-form {
    width: 90%;
    height: 30%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }

  .title-parraf-contact-form h3 {
    max-width: 500px;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }

  .title-parraf-contact-form p {
    max-width: 300px;
    text-align: center;
    width: 80%;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
  }

  .contact-form-container {
    width: 100%;
    height: 70%;
    max-height: 400px;
    position: relative;
    padding: 0%;
  }

  .contact-form-container label {
    width: 40%;
    min-height: 50px;
    min-width: 400px;
    height: 60px;
    outline: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 50px;
    position: relative;
  }

  .contact-form-container input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .contact-form-container input::placeholder {
    text-align: center;
  }

  .box-icon-contact-form {
    width: 50px;
    height: 50px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 5px;
    position: absolute;
  }

  .box-icon-contact-form img {
    width: 26px;
  }

  .contact-form-container button {
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 15px 30px;
    border: 2px solid red;
    background-color: rgb(255, 0, 0);
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-form-container span {
    position: absolute;
    padding: 200px 200px;
    background-color: red;
    border-radius: 24px;
    z-index: -1;
  }

  .contact-form-container button {
    background-color: red;
    border: 2px solid white;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .contact-form-container button:hover {
    background-color: white;
    color: red;
    cursor: pointer;
  }

  .contact-form-bg {
    width: 100%;
    height: 760px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 24px;
  }
}

@media (max-width: 600px) {
  .insurance-contact {
    width: 100%;
    margin-top: 5%;
  }

  .contacting-container {
    width: 100%;
    height: 100%;
  }

  .contact-container {
    width: 100%;
    height: 760px;
    min-height: 600px;
    gap: 0px;
  }

  .title-parraf-contact-form {
    width: 90%;
    height: 30%;
    min-height: 200px;
  }

  .title-parraf-contact-form h3 {
    max-width: 500px;
    width: 100%;
  }

  .title-parraf-contact-form p {
    max-width: 300px;
  }

  .contact-form-container {
    width: 100%;
    height: 70%;
  }

  .contact-form-container label {
    width: 60%;
    min-height: 50px;
    min-width: 300px;
    height: 60px;
  }

  .contact-form-container input {
    width: 100%;
    height: 100%;
  }

  .contact-form-container input::placeholder {
    text-align: center;
  }

  .box-icon-contact-form {
    width: 50px;
    height: 50px;
    background-color: red;
  }

  .box-icon-contact-form img {
    width: 26px;
  }

  .contact-form-container button {
    text-transform: uppercase;
    padding: 15px 50px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-form-container span {
    position: absolute;
    background-color: red;
    border-radius: 24px;
    z-index: -1;
  }

  .contact-form-container button {
    background-color: red;
    border: 2px solid white;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .contact-form-container button:hover {
    background-color: white;
    color: red;
    cursor: pointer;
  }

  .contact-form-bg {
    width: 100%;
    height: 760px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}
