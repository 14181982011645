.financy-container {
  width: 80%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.858);
  margin: 5% 0 10% 0;
}

.financing-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5%;
}

.title-parraf-financy-form {
  width: 60%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}

.title-parraf-financy-form h3 {
  max-width: 300px;
  width: 80%;
  font-size: 2.75rem;
  font-weight: bold;
  color: white;
}

.title-parraf-financy-form p {
  width: 65%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.financy-form-container {
  width: 50%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 24px;
  gap: 30px;
  background-color: red;
  padding: 2% 0%;
}

.financy-form-container label {
  width: 80%;
  min-height: 60px;
  height: 70px;
  outline: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 50px;
  position: relative;
}

.financy-form-container input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 1rem;
}

.financy-form-container input::placeholder {
  text-align: center;
}

.box-icon-financy-form {
  width: 60px;
  height: 60px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  position: absolute;
}

.box-icon-financy-form img {
  width: 26px;
}

.financy-form-container button {
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 20px 25px;
  border: 2px solid red;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 16px;
  cursor: pointer;
  border: 2px solid white;
  font-size: 1.1rem;
  font-weight: bold;
}

.financy-form-container button:hover {
  background-color: white;
  color: red;
  cursor: pointer;
}

.financy-form-bg {
  width: 80%;
  height: 700px;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  border-radius: 24px;
}

@media (max-width: 1024px) {
  .financy-container {
    width: 60%;
    height: 700px;
    min-height: 600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.872);
    margin: 5% 0 10% 0;
  }

  .financing-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0%;
  }

  .title-parraf-financy-form {
    width: 90%;
    height: 400px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }

  .title-parraf-financy-form h3 {
    max-width: 500px;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }

  .title-parraf-financy-form p {
    max-width: 300px;
    text-align: center;
    width: 80%;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
  }

  .financy-form-container {
    width: 100%;
    height: 500px;
    min-height: 500px;
    position: relative;
    padding: 0%;
  }

  .financy-form-container label {
    width: 80%;
    min-height: 50px;
    height: 70px;
    outline: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 50px;
    position: relative;
  }

  .financy-form-container input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .financy-form-container input::placeholder {
    text-align: center;
  }

  .box-icon-financy-form {
    width: 50px;
    height: 50px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 5px;
    position: absolute;
  }

  .box-icon-financy-form img {
    width: 26px;
  }

  .financy-form-container button {
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 15px 15px;
    border: 2px solid red;
    background-color: rgb(255, 0, 0);
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }

  .financy-form-container span {
    position: absolute;
    padding: 200px 200px;
    background-color: red;
    border-radius: 24px;
    z-index: -1;
  }

  .financy-form-container button {
    background-color: black;
    border: 2px solid white;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .financy-form-container button:hover {
    background-color: white;
    color: red;
    cursor: pointer;
  }

  .financy-form-bg {
    width: 60%;
    height: 700px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 24px;
  }
}

@media (max-width: 600px) {
  .financy-container {
    width: 100%;
    height: 100vh;
    max-height: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0.872);
    margin: 5% 0 10% 0;
  }

  .financing-container {
    width: 100%;
    height: 100%;
    margin: 0%;
  }

  .title-parraf-financy-form {
    width: 90%;
    height: 40%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .title-parraf-financy-form h3 {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 1.1;
    color: white;
  }

  .title-parraf-financy-form p {
    max-width: 300px;
    text-align: center;
    width: 80%;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
  }

  .financy-form-container {
    width: 100%;
    height: 60%;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 24px;
    gap: 30px;
    background-color: red;
    padding: 0%;
  }

  .financy-form-container label {
    width: 70%;
    height: 50px;
    outline: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 50px;
    position: relative;
  }

  .financy-form-container input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .financy-form-container input::placeholder {
    text-align: center;
  }

  .box-icon-financy-form {
    width: 40px;
    height: 40px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 5px;
    position: absolute;
  }

  .box-icon-financy-form img {
    width: 20px;
  }

  .financy-form-container button {
    text-transform: uppercase;
    font-size: 0.9rem;
    padding: 15px 15px;
    border: 2px solid red;
    background-color: rgb(255, 0, 0);
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }

  .financy-form-container span {
    position: absolute;
    padding: 200px 200px;
    background-color: red;
    border-radius: 24px;
    z-index: -1;
  }

  .financy-form-container button {
    background-color: black;
    border: 2px solid white;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .financy-form-container button:hover {
    background-color: white;
    color: red;
    cursor: pointer;
  }

  .financy-form-bg {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: 24px;
  }
}
