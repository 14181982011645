.storm-intro-container {
  width: 100%;
  padding-top: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 70px;
}

.storm-intro-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 20%;
}

.storm-intro-title h2 {
  width: 100%;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.storm-intro-title h2 span {
  color: red;
}

.storm-items {
  width: 90%;
  height: 520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.storm-intro-info {
  width: 40%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.storm-intro-info p {
  width: 80%;
  color: white;
  font-size: 1.1rem;
  letter-spacing: 1.5px;
  line-height: 1.6;
}

.storm-intro-btn {
  width: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  background-color: rgba(188, 0, 0, 0.925);
  color: white;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  border-radius: 16px;
  border: 2px solid red;
  padding: 15px 10px;
}

.storm-intro-btn:hover {
  background-color: rgba(0, 0, 0, 0.215);
  color: red;
}

.second-storm-column {
  width: 55%;
  height: 400px;
  min-width: 360px;
}

.second-storm-column img {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  object-fit: cover;
}

.black-span-bg {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  background-color: black;
  border-radius: 0 36px 36px 0;
  left: -25%;
}

@media (max-width: 1024px) {
  .storm-intro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
  }

  .storm-intro-title h2 {
    width: 100%;
    font-size: 2.7rem;
    font-weight: bold;
    text-align: center;
  }

  .storm-items {
    width: 90%;
    height: 700px;
    flex-direction: column-reverse;
  }

  .storm-intro-info {
    width: 80%;
    height: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .storm-intro-info p {
    width: 100%;
    color: white;
    font-size: 1rem;
    letter-spacing: 1.4px;
    line-height: 1.5;
    text-align: center;
  }

  .second-storm-column {
    width: 80%;
    height: 50%;
  }

  .black-span-bg {
    display: none;
  }

  .black-span-bg-responsive {
    width: 100%;
    height: 75%;
    bottom: 0;
    z-index: -1;
    position: absolute;
    background-color: rgb(0, 0, 0);
    border-radius: 36px;
  }
}

@media (max-width: 600px) {

  .storm-intro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 20%;
  }

  .storm-intro-title h2 {
    width: 90%;
    font-size: 2.3rem;
  }

  .storm-items {
    width: 100%;
  }

  .storm-intro-info {
    width: 80%;
  }

  .storm-intro-info p {
    width: 100%;
    color: white;
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.5;
    text-align: center;
  }

  .second-storm-column {
    width: 100%;
    height: 50%;
  }

  .black-span-bg-responsive {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    background-color: rgb(0, 0, 0);
    border-radius: 36px;
  }
}
