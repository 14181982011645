.emergency-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  position: relative;
}

.intro-items-container {
  width: 40%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emergency-title {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.emergency-title h2 {
  width: 100%;
  font-size: 3rem;
  font-weight: bold;
}

.emergency-title h2 span {
  color: red;
}

.emergency-intro-info {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 30px;
}


.emergency-intro-info h4 {
  font-size: 1.6rem;
  font-weight: bold;
}

.emergency-intro-info h4 span {
  color: red;
}

.emergency-intro-info p {
  font-size: 1.2rem;
  letter-spacing: 1px;
  line-height: 1.1;
}

.emergency-intro-info a {
  font-size: 1.2rem;
  font-weight: bold;
  background-color: red;
  color: white;
  text-decoration: none;
  padding: 13px 15px;
  border-radius: 12px;
  border: 2px solid red;
  letter-spacing: 1px;
}

.emergency-intro-info a:hover{
  background-color: white;
  color: red;
  border: 2px solid red;
  transition: all ease-in-out 0.3s;
}

.first-emergency-img {
  width: 30%;
  max-width: 350px;
  height: 250px;
  border-radius: 0 36px 36px 0;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.second-emergency-img {
  width: 30%;
  max-width: 350px;
  height: 250px;
  border-radius: 36px 0 0 36px;
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

@media (max-width: 1024px) {

  .intro-items-container {
    width: 50%;
    height: 400px;
  }

  .emergency-title {
    height: 25%;
  }

  .emergency-title h2 {
    font-size: 2rem;
  }

  .emergency-intro-info {
    height: 75%;
    justify-content: space-evenly;
    gap: 0px;
  }


  .emergency-intro-info h4 {
    font-size: 1.4rem;
    font-weight: bold;
  }

  .emergency-intro-info h4 span {
    color: red;
  }

  .emergency-intro-info p {
    font-size: 1rem;
  }

  .emergency-intro-info a {
    font-size: 1.2rem;
    padding: 15px 10px;
  }

  .first-emergency-img {
    width: 25%;
    max-width: 350px;
    height: 250px;
  }

  .second-emergency-img {
    width: 25%;
    max-width: 350px;
    height: 250px;
  }
}

@media (max-width: 600px) {
  .emergency-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    position: relative;
  }

  .first-emergency-img {
    width: 100%;
    max-width: 100%;
    height: 30%;
    min-height: 250px;
    border-radius: 24px;
    position: relative;
  }

  .intro-items-container {
    width: 90%;
    height: 55%;
    margin-bottom: 50px;
    gap: 25px;
  }

  .emergency-title {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .emergency-title h2 {
    width: 100%;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 40px;
  }

  .emergency-intro-info {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: space-evenly;
    gap: 0px;
    
  }
  .emergency-intro-info h4 {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .emergency-intro-info p {
    font-size: 1rem;
  }

  .emergency-intro-info a {
    font-size: 1.2rem;
    padding: 15px 10px;
    margin-top: 35px;
  }

  .second-emergency-img {
    width: 100%;
    max-width: 100%;
    height: 30%;
    border-radius: 24px;
    position: relative;
  }
}
