.servicepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
}

.service-third-title {
  font-size: 1.1rem;
  padding: 7px 12px;
  background-color: red;
  color: white;
  line-height: 1.1;
  margin-top: 70px;
}

.service-main-title {
  font-size: 7vh;
  font-weight: bold;
  text-align: center;
  line-height: 1.1;
  margin-top: 20px;
}

.service-main-title span {
  color: red;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  height: 30vh;
  margin-top: 40px;
  margin-bottom: 80px;
}

.slider-service-page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  gap: 2px;
  border-radius: 30px;
}

.carousel-item {
  position: relative;
  min-width: 26.5vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel-img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.box-name-service {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.815);
}

.box-name-service:hover {
  background-color: rgba(175, 0, 0, 0.842);
}

.box-name-service.active {
  background-color: rgba(175, 0, 0, 0.842);
}

.name-service {
  color: white;
  width: 60%;
  text-align: center;
  line-height: 1.4;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.white-box-left {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 0px;
  height: 100%;
}

.white-box-right {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  right: 0px;
  height: 100%;
}

.arrow-btn-left {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: red;
  outline: 3px solid white;
  cursor: pointer;
  margin-left: -25px;
}

.arrow-btn-right {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: red;
  position: absolute;
  z-index: 2;
  right: 20%;
  outline: 3px solid white;
  cursor: pointer;
  margin-right: -25px;
}

.arrow-btn-left img,
.arrow-btn-right img {
  width: 25px;
  color: white;
}

.columns-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.article-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.first-article-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 70px 0px;
}

.first-article {
  color: white;
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.first-article-title {
  font-size: 2.1rem;
  font-weight: bold;
  color: white;
  line-height: 1.2;
  width: 60%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.service-parraf {
  font-size: 0.9rem;
  width: 85%;
  line-height: 1.5;
  letter-spacing: 1px;
}

.black-background {
  position: absolute;
  width: 150%;
  height: 100%;
  background-color: black;
  z-index: -1;
  border-radius: 20px;
}

.black-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(1);
  opacity: 4%;
}

.service-contact {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 70%;
  margin-top: 60px;
}
.reserve-btn {
  background-color: rgba(255, 0, 0, 0.644);
  color: white;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
  border: 2px solid red;
  padding: 15px 22px;
  letter-spacing: 1px;
  cursor: pointer;
  text-decoration: none;
}

.reserve-btn:hover {
  background-color: white;
  color: red;
  border: 2px solid white;
}

.service-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.service-social-icons a {
  background-color: black;
  border-radius: 50%;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.service-social-icons a:hover {
  background-color: red;
  border: 2px solid red;
}

.service-social-icons a img {
  width: 22px;
}

.second-article-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 50px 0px;
}

.second-article {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.second-article-title {
  font-size: 2.1rem;
  font-weight: bold;
  color: red;
  text-transform: uppercase;
  width: 60%;
  letter-spacing: 1px;
}

.service-parraf-two {
  font-size: 1rem;
  width: 100%;
  line-height: 1.4;
  font-weight: bold;
}

.service-parraf-three {
  font-size: 1rem;
  width: 100%;
  line-height: 1.4;
  letter-spacing: 1;
  list-style-type: disc;
}

.service-parraf-three li {
  margin-bottom: 5px;
}

.alternate-parraf {
  font-size: 1rem;
  line-height: 1.4;
}

.service-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  gap: 80px;
  width: 50%;
  padding-top: 70px;
}

.image-section-services {
  width: 75%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 30px;
}

.lupa-container {
  position: relative;
}

.service-images {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
  transition: transform 0.3s;
}

.image-section-services:hover {
  & .service-images {
    transform: scale(1.1);
  }
}

.box-zoom-img {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  background-color: red;
}

.box-zoom-img img {
  width: 27px;
  margin-bottom: 2px;
}

.article-responsive {
  display: none;
}

@media (max-width: 1024px) {
  .servicepage-container {
    margin-bottom: 20px;
  }

  .service-main-title {
    font-size: 5vh;
  }

  .name-service {
    line-height: 1.4;
    font-size: 2.5vw;
  }

  .arrow-btn-left {
    margin-left: -65px;
  }

  .arrow-btn-right {
    margin-right: -65px;
  }

  .article-container {
    width: 60%;
  }

  .service-img {
    width: 40%;
    align-items: flex-end;
    gap: 100px;
  }

  .first-article {
    width: 80%;
  }

  .first-article-title {
    font-size: 5.5vw;
  }

  .image-section-services {
    width: 100%;
    height: 470px;
    border-radius: 30px 0px 0px 30px;
  }

  .service-images {
    border-radius: 30px 0px 0px 30px;
  }

  .service-parraf {
    font-size: 1rem;
  }

  .service-contact {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
  }

  .second-article {
    width: 80%;
  }

  .second-article-title {
    font-size: 2.3rem;
    width: 65%;
  }

  .service-parraf-two {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .service-main-title {
    font-size: 2.2rem;
    width: 70%;
    max-width: 260px;
    line-height: 1;
    margin-top: 20px;
  }

  .carousel-container {
    width: 100%;
    height: 35vh;
    margin-top: 55px;
    margin-bottom: 35px;
  }

  .white-box-left {
    align-items: flex-end;
  }

  .white-box-right {
    align-items: flex-end;
  }

  .arrow-btn-left {
    border-radius: 0px 30px 30px 0px;
    width: 45px;
    height: 60px;
    margin-left: 0px;
    margin-bottom: -35px;
  }

  .arrow-btn-right {
    border-radius: 30px 0px 0px 30px;
    width: 45px;
    height: 60px;
    margin-right: 0px;
    margin-bottom: -35px;
  }

  .carousel-item {
    min-width: 33vw;
  }

  .name-service {
    width: 100%;
    line-height: 1.4;
    font-size: 4vw;
    letter-spacing: 0.5px;
  }

  .slider-service-page {
    border-radius: 0px;
    overflow-x: auto;
  }

  .slider-service-page::-webkit-scrollbar {
    height: 0px;
  }

  .article-container {
    display: none;
  }

  .service-img {
    display: none;
  }

  .article-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .article-responsive-first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 50px 0px;
  }

  .article-responsive-first h2 {
    font-size: 1.8rem;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    width: 70%;
    text-align: center;
  }

  .article-img-responsive-one {
    width: 80%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 40px;
  }

  .service-images-responsive {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .article-responsive-first p {
    width: 80%;
    text-align: center;
    font-size: 0.8rem;
    line-height: 1.3;
    margin-top: 50px;
    color: white;
    letter-spacing: 1px;
  }

  .cont-button-article-responsive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .black-background-responsive {
    position: absolute;
    width: 100%;
    height: 77%;
    background-color: black;
    z-index: -1;
    border-radius: 30px;
    top: 250px;
  }

  .black-background-responsive img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
    opacity: 4%;
  }

  .article-responsive-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }

  .article-responsive-two h3 {
    font-size: 1.7rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    width: 60%;
    line-height: 1.1;
    letter-spacing: 1px;
    color: red;
  }

  .article-responsive-two p {
    font-size: 1rem;
    width: 80%;
    text-align: center;
    line-height: 1.3;
    margin-top: 30px;
  }

  .box-lista-service {
    margin-top: 40px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    list-style-type: disc;
  }
}
