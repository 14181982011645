.emergency-benefits-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 110px 0px 50px 0px;
}

.card-benefits-section {
  width: 80%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.card-benefits-container {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 24px;
  position: relative;
}

.first-span {
  background-color: red;
  position: absolute;
  width: 40%;
  height: 40%;
  border-radius: 24px 24px;
  top: 0;
  right: 0;
}

.second-span {
  background-color: red;
  position: absolute;
  width: 40%;
  height: 40%;
  border-radius: 12px 24px;
  bottom: 0;
  left: 0;
}

.card-items-container {
  width: 94%;
  height: 94%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  background-color: white;
  border-radius: 24px;
  z-index: 1;
}

.card-items-container img {
  width: 60px;
  height: 60px;
  padding: 4px;
  margin-left: 20px;
  border-radius: 12px;
  background-color: red;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.114);
}

.card-items-container h6 {
  width: 85%;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 20px;
}

.card-items-container p {
  width: 85%;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.1;
  margin-left: 20px;
}

@media (max-width: 1024px) {
  .emergency-benefits-container {
    width: 100%;
    height: 100%;
    margin-top: 140px;
  }

  .card-benefits-section {
    height: fit-content;
    flex-flow: wrap;
    gap: 40px;
  }

  .card-benefits-container {
    width: 400px;
    height: 280px;
  }

  .card-items-container {
    width: 94%;
    height: 94%;
  }

  .card-items-container img {
    width: 50px;
    height: 50px;
    margin-left: 15px;
  }

  .card-items-container h6 {
    font-size: 1.3rem;
    margin-left: 15px;
  }

  .card-items-container p {
    font-size: 1rem;
    margin-left: 15px;
  }
}

@media (max-width: 850px) {
  .emergency-benefits-container {
    width: 100%;
    height: 100%;
    margin-top: 80px;
  }

  .card-benefits-section {
    height: fit-content;
    flex-flow: wrap;
    gap: 20px;
  }

  .card-benefits-container {
    width: 90%;
    max-width: 380px;
    height: 200px;
  }

  .first-span {
    width: 40%;
    height: 40%;
    top: 0;
    right: 0;
  }

  .second-span {
    width: 40%;
    height: 40%;
    bottom: 0;
    left: 0;
  }

  .card-items-container {
    width: 94%;
    height: 94%;
    background-color: white;
    z-index: 1;
  }

  .card-items-container img {
    width: 30px;
    height: 30px;
    padding: 4px;
    margin-left: 15px;
    border-radius: 8px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.114);
  }

  .card-items-container h6 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 15px;
  }

  .card-items-container p {
    font-size: 0.9rem;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-left: 15px;
  }
}
