.section-services-area {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 5%;
}

.bg-section-space {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-section-space .bg-service-area {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.bg-section-space .bg-service-area-responsive {
  display: none;
}

.info-section-services {
  z-index: 1;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.info-services {
  width: 80%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.info-services h4 {
  color: white;
  text-transform: uppercase;
  font-size: 1.1rem;
  background-color: red;
  padding: 7px 10px;
  display: inline-block;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.info-services h2 {
  font-size: 2.2rem;
  color: white;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: 1px;
}

.info-services h3 {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.btn-slice-services {
  width: 80%;
  height: 15%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
}

.btn-slice-services button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  outline: 2px solid white;
  background-color: rgba(0, 0, 0, 0.575);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.btn-slice-services button img {
  width: 13px;
}

.btn-slice-services button:first-child img {
  transform: rotate(180deg);
}

.btn-slice-services button:hover {
  background-color: rgb(255, 0, 0);
}

.btn-slice-services-responsive {
  display: none;
}

.slider-section-services {
  z-index: 1;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.item-slider {
  min-width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.slider:first-child {
  border-radius: 25px 0px 0px 25px;
}

.gradient-name-service {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.692));
}

.item-slider:hover {
  & .gradient-name-service {
    height: 60%;
    background: linear-gradient(rgba(255, 0, 0, 0), rgb(255, 0, 0));
  }

  .box-arrow {
    background-color: rgb(255, 0, 0);
  }
}

.gradient-name-service h3 {
  color: white;
  font-size: 1.3rem;
  width: 70%;
  margin-left: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}

.box-arrow {
  padding: 8px 10px;
  border-radius: 36px;
  outline: 1px solid white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  background-color: rgba(0, 0, 0, 0.623);
  margin: 20px;
}

.box-arrow img {
  width: 13px;
}

.box-arrow p{
  font-size: 0.9rem;
}

.img-item-services {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

@media (max-width: 1024px) {
  .section-services-area {
    flex-direction: column;
    align-items: center;
  }

  .info-section-services {
    width: 100%;
    height: 35%;
  }

  .info-services {
    width: 85%;
    height: 65%;
    justify-content: center;
  }

  .info-services h2 {
    font-size: 4.5vh;
  }

  .info-services h3 {
    font-size: 2.5vh;
  }

  .slider-section-services {
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-slice-services {
    display: none;
  }

  .btn-slice-services-responsive {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20%;
    width: 85%;
    gap: 20px;
    z-index: 2;
  }

  .btn-slice-services-responsive button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    outline: 2px solid white;
    background-color: rgba(0, 0, 0, 0.575);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .btn-slice-services-responsive button img {
    width: 13px;
  }

  .btn-slice-services-responsive button:first-child img {
    transform: rotate(180deg);
  }

  .btn-slice-services-responsive button:hover {
    background-color: rgb(255, 0, 0);
  }

  .slider {
    height: 100%;
    padding-left: 7%;
  }

  .slider::-webkit-scrollbar {
    height: 0px;
  }

  .bg-section-space .bg-service-area {
    display: none;
  }

  .bg-section-space .bg-service-area-responsive {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }
}

@media (max-width: 600px) {
  .section-services-area {
    justify-content: flex-start;
  }


  .info-services h2 {
    font-size: 1.6rem;
  }

  .info-services h3 {
    font-size: 1.1rem;
  }

  .info-section-services {
    width: 100%;
    height: 30%;
  }

  .slider-section-services {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider {
    height: 100%;
    padding-left: 0;
    overflow-x: auto;
  }

  .slider:first-child {
    border-radius: 0px 0px 0px 0px;
  }

  .item-slider {
    min-width: 60%;
    height: 100%;
  }
}
