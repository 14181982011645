.form-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  position: relative;
}

.form-container label {
  width: 100%;
  height: 50px;
  outline: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 50px;
  position: relative;
}

.form-container input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border: none;
  text-align: center;
}

.form-container input::placeholder {
  text-align: center;
}

.box-icon-form {
  width: 40px;
  height: 40px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
  position: absolute;
}

.box-icon-form img {
  width: 16px;
}

.form-container button {
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 10px 15px;
  border: 2px solid red;
  background-color: rgba(255, 0, 0, 0.863);
  color: white;
  border-radius: 10px;
  cursor: pointer;
}

.success-message{
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  background-color: rgba(24, 166, 76, 0.906);
  border-radius: 24px;
  padding: 10px;
  position: absolute;
  gap: 20px;
}

.success-message img{
  width: 70px;
}

@media (max-width: 1024px) {
  .form-container {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
}

@media (max-width: 600px) {
  .form-container {
    width: 70%;
  }
}
