.cont-body-app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cont-general-app {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.bg-home {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  object-fit: cover;
  z-index: -1;
}

.bg-home-mobile{
  display: none;
}

.left-home-cont {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  animation: titleHome 2s ease-in-out;
}

@keyframes titleHome {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cont-title {
  width: 90%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.cont-info-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cont-info-items h1 {
  font-size: 6vh;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 2.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cont-info-items h1 .box-first-title{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cont-info-items h1 .first {
  background-color: rgba(255, 0, 0, 0.884);
  padding: 4px 10px;
  border-radius: 10px 10px 0px 0px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .second {
  background-color: rgba(0, 0, 0, 0.76);
  padding: 4px 10px;
  border-radius: 0px 10px 0px 0px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .tercer {
  background-color: rgba(255, 0, 0, 0.884);
  padding: 4px 10px;
  border-radius: 0px 10px 10px 10px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .second-service {
  background-color: rgba(0, 0, 0, 0.76);
  padding: 4px 10px;
  border-radius: 10px 10px 10px 0px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .tercer-service {
  background-color: rgba(255, 0, 0, 0.884);
  padding: 4px 10px;
  border-radius: 0px 0px 10px 10px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .second-contact {
  background-color: rgba(0, 0, 0, 0.76);
  padding: 4px 10px;
  border-radius: 10px 10px 0px 0px;
  backdrop-filter: blur(10px);
}

.cont-info-items h1 .tercer-contact {
  background-color: rgba(255, 0, 0, 0.884);
  padding: 4px 10px;
  border-radius: 0px 10px 10px 10px;
  backdrop-filter: blur(10px);
}

.cont-title h2 {
  font-size: 3vh;
  color: white;
  margin-top: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 1.2;
  margin-left: 10px;
  text-shadow: 0px 0px 10px black;
  width: 75%;
}

.cont-contact-home {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 8%;
}

.btn-contact-responsive{
  display:none;
}

.cont-contact-home .btn-contact {
  background-color: rgba(255, 0, 0, 0.822);
  color: white;
  padding: 12px 15px;
  border: 2px solid red;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 2px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display:block;
}

.cont-contact-home .btn-contact:hover {
  border: 2px solid white;
  background-color: white;
  color: red;
}

.right-home-cont {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 40px;
}

.cont-certified-home {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 5%;
  margin-right: 5%;
  animation: titleHome 2s ease-in-out;
}

.cont-certified-home img {
  width: 80px;
}

@media (max-width: 1024px) {
  .cont-contact-home {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .cont-info-items h1 {
    font-size: 5vh;
    line-height: 1.3;
  }

  .cont-title h2 {
    font-size: 2.5vh;
  }

  .cont-contact-home .btn-contact {
    font-size: 2.5vh;
    padding: 15px 25px;
  }
}

@media (max-width: 600px){
  .cont-general-app {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .left-home-cont {
    width: 100%;
    height: 50%;
    justify-content: flex-end;
  }

  .right-home-cont {
    width: 100%;
    height: 50%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 20px;
  }

  .cont-title {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .cont-info-items {
    align-items: center;
  }
  
  .cont-info-items h1 {
    font-size: 1.4rem;
    text-align: center;
    align-items: center;
  }

  .cont-info-items h1 .box-first-title{
    flex-direction: row;
    align-items: center;
    justify-content:center;
  }

  .cont-info-items h1 .first {
    background-color: rgba(255, 0, 0, 0.884);
    padding: 4px 10px;
    border-radius: 10px 0px 0px 10px;
    backdrop-filter: blur(10px);
  }
  
  .cont-info-items h1 .second {
    background-color: rgba(255, 0, 0, 0.884);
    padding: 4px 10px 4px 0px;
    border-radius: 0px 10px 10px 0px;
    backdrop-filter: blur(10px);
    margin-left: -2px;
  }
  
  .cont-info-items h1 .tercer {
    background-color: rgba(0, 0, 0, 0.76);
    border-radius: 0px 0px 10px 10px;
  }

  .cont-info-items h1 .second-service {
    border-radius: 10px 10px 10px 10px;
  }
  
  .cont-info-items h1 .tercer-service {
    background-color: rgba(255, 0, 0, 0.884);
    padding: 4px 10px;
    border-radius: 0px 0px 10px 10px;
    backdrop-filter: blur(10px);
  }

  .cont-info-items h1 .second-contact {
    border-radius: 10px 10px 0px 0px;
  }

  .cont-info-items h1 .tercer-contact {
    padding: 4px 12px;
    border-radius: 10px 10px 10px 10px;
  }

  .cont-title h2 {
    font-size: 1.0rem;
    text-align: center;
  }

  .cont-certified-home {
    flex-direction: column;
    margin-bottom: 5%;
    margin-right: 5%;
  }

  .cont-certified-home img {
    width: 65px;
  }

  .cont-contact-home {
    display: none;
  }

  .btn-contact-responsive{
    display: block;
    background-color: rgba(255, 0, 0, 0.822);
    color: white;
    padding: 12px 20px;
    border: 2px solid red;
    font-size: 1rem;
    text-transform: uppercase;
    border-radius: 10px;
    letter-spacing: 2px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    position: absolute;
    left: 10%;
    bottom: 5%;
  }
  
  .btn-contact-responsive:hover {
    border: 2px solid white;
    background-color: white;
    color: red;
  }

  .bg-home {
    display: none;
    
  }
  
  .bg-home-mobile{
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -1;
  }
}