.section-contact-area {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.info-contact-area {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 650px;
}

.cont-info-area {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.cont-info-area h4 {
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 7px 10px;
  background-color: #ff0000;
  display: inline-block;
}

.cont-info-area h2 {
  color: black;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-align: left;
  margin-top: 10px;
  font-weight: bold;
  letter-spacing: 2px;
}

.cont-info-area span {
  color: #ff0000;
}

.cont-info-area p {
  margin-top: 20px;
  width: 80%;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 1px;
}

.btns-cont-contact {
  width: 60%;
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

.btns-cont-contact a {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.767);
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.btns-cont-contact a:hover {
  background-color: red;
  border: 2px solid red;
}

.btns-cont-contact a img {
  width: 26px;
}

.formulario-contact-area {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 650px;
}

.bg-contact-area {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.bg-contact-area span:first-child {
  width: 100%;
  height: 50vh;
  position: absolute;
  background: linear-gradient(white, rgba(255, 255, 255, 0));
}

.bg-contact-area span:nth-child(2) {
  width: 100%;
  height: 50vh;
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
  bottom: 0px;
}

.bg-contact-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .section-contact-area {
    height: 800px;
    flex-direction: column;
    gap: 60px;
  }

  .info-contact-area {
    width: 100%;
    height: 50%;
    justify-content: flex-end;
  }

  .formulario-contact-area {
    width: 100%;
    height: 50%;
    align-items: flex-start;
  }
  
  .cont-info-area {
    width: 60%;
    align-items: center;
  }
  
  .cont-info-area h2 {
    text-align: center;
  }

  .cont-info-area p {
    font-size: 1.1rem;
    text-align: center;
  }

  .btns-cont-contact {
    margin-top: 30px;
    justify-content: center;
    gap: 25px;
  }
  
  
}

@media (max-width: 600px) {
  .cont-info-area h2 {
    font-size: 2.0rem;
    margin-top: 15px;
  }
  
  .cont-info-area p {
    margin-top: 20px;
    width: 100%;
  }

}
