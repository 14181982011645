.insurance-claims-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px 0;
  gap: 20px;
}

.insurance-claims-title {
  width: 90%;
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-claims-title span {
  color: red;
  margin-right: 10px;
}

.insurance-claims-info {
  font-size: 1.3rem;
  font-weight: 400;
  color: rgb(0, 0, 0);
  width: 60%;
  text-align: center;
  margin-bottom: 60px;
  line-height: 1.2;
}

.insurance-claims-tag {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.insurance-claims-tag p {
  font-size: 1.1rem;
  font-weight: 500;
  background-color: red;
  color: white;
  padding: 10px;
}

.insurance-claims-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  gap: 50px;
}

.insurance-claims-card1,
.insurance-claims-card2,
.insurance-claims-card3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  height: 250px;
  background: linear-gradient(to right, #ffffff, #c3c3c3);
  border-radius: 40px;
}

.text-claim-cards-container {
  height: 80%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.text-claim-cards-container h4 {
  font-size: 1.8rem;
  font-weight: bold;
  color: red;
}

.first-insurance-card-img {
  width: 35%;
  min-width: 300px;
  height: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-insurance-card-img img {
  width: 100%;
  height: 100%;
  border-radius: 36px;
  object-fit: cover;
}

.insurance-claims-card1 p,
.insurance-claims-card2 p,
.insurance-claims-card3 p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-size: 1rem;
  line-height: 1.3;
  font-weight: 500;
}

@media (max-width: 1024px) {
  .insurance-claims-container {
    width: 100%;
  }

  .insurance-claims-title {
    font-size: 3rem;
    flex-direction: column;
  }

  .insurance-claims-info {
    width: 80%;
  }

  .insurance-claims-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    gap: 50px;
  }

  .insurance-claims-card1,
  .insurance-claims-card2,
  .insurance-claims-card3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  .text-claim-cards-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .first-insurance-card-img {
    width: 30%;
    min-width: 300px;
    height: 100%;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .first-insurance-card-img img {
    width: 100%;
    height: 100%;
    border-radius: 36px;
    object-fit: cover;
  }

  .insurance-claims-card1 p,
  .insurance-claims-card2 p,
  .insurance-claims-card3 p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    font-size: 1rem;
  }
}

@media (max-width: 700px) {
  .insurance-claims-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 60px 0;
  }

  .insurance-claims-title {
    width: 80%;
    font-size: 2rem;
    font-weight: bold;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .insurance-claims-title span {
    color: red;
  }

  .insurance-claims-info {
   font-size: 1.1rem;
  }

 

  .insurance-claims-tag {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .insurance-claims-tag p {
    font-size: 1.1rem;
    font-weight: 500;
    background-color: red;
    color: white;
    padding: 10px;
  }

  .insurance-claims-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .insurance-claims-card1,
  .insurance-claims-card2,
  .insurance-claims-card3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    height: 560px;
    background: linear-gradient(to bottom, #ffffff, #d1d1d1de);
  }

  .text-claim-cards-container {
    height: 40%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .text-claim-cards-container h4 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .first-insurance-card-img {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .first-insurance-card-img img {
    width: 100%;
    border-radius: 36px;
    object-fit: cover;
  }

  .insurance-claims-card1 p,
  .insurance-claims-card2 p,
  .insurance-claims-card3 p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    font-size: 0.9rem;
    line-height: 1.3;
    font-weight: 500;
  }
}
