.section-footer {
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
}

.footer-first {
  width: 85%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer-logo {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}

.footer-logo p {
  font-size: 0.9rem;
  width: 75%;
  line-height: 1.5;
  letter-spacing: 1px;
}

.logos-certified-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 25px;
}

.logos-certified-footer img {
  width: 75px;
}

.logo-footer {
  width: 50%;
}

.footer-container-responsive {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-services {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.footer-services p {
  font-size: 0.9rem;
  border: 2px solid white;
  padding: 10px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-services ul {
  list-style-type: disc;
}

.footer-services ul li {
  margin-bottom: 10px;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.footer-services ul li .link-foot {
  text-decoration: none;
  color: white;
}

.footer-services ul li .link-foot:hover {
  border-bottom: 1px solid white;
}

.footer-contact {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.title-footer-sec {
  font-size: 0.9rem;
  border: 2px solid white;
  padding: 10px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-contact ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  font-size: 0.9rem;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.footer-contact ul li a {
  color: white;
  text-decoration: none;
}

.footer-contact ul li a:hover {
  border-bottom: 1px solid white;
}

.footer-contact ul li img {
  width: 20px;
}

.box-social-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
}

.box-item-social {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-item-social:hover {
  background-color: red;
  border: 2px solid red;
}

.box-item-social img {
  width: 23px;
}

.footer-last {
  border-top: 1px solid white;
  width: 85%;
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 1024px) {
  .section-footer {
    height: 700px;
  }

  .footer-logo {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
  }

  .logo-footer {
    width: 60%;
  }

  .logos-certified-footer {
    margin-top: 80px;
  }

  .footer-container-responsive {
    flex-direction: column;
    width: 50%;
    gap: 50px;
  }

  .footer-services {
    width: 100%;
    height: 50%;
    gap: 20px;
  }

  .footer-services ul {
    list-style-type: disc;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 100px;
  }

  .footer-contact {
    width: 100%;
    height: 50%;
    gap: 20px;
  }

  .footer-contact ul li {
    margin-bottom: 20px;
  }

  .footer-contact span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .box-social-footer {
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .section-footer {
    height: 1200px;
  }

  .footer-first {
    width: 85%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
  .footer-logo {
    width: 100%;
    align-items: center;
  }

  .footer-logo p {
    text-align: center;
  }
  .logos-certified-footer {
    gap: 30px;
    margin-top: 20px;
  }

  .logos-certified-footer img {
    width: 90px;
  }

  .footer-container-responsive {
    width: 100%;
  }
  .footer-services {
    width: 80%;
  }

  .footer-services ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .footer-contact {
    width: 80%;
  }

  .footer-contact span {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .box-social-footer {
    flex-direction: row;
    gap: 30px;
    margin-top: 10px;
  }

  .footer-last {
    border-top: 1px solid white;
    width: 85%;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 40px;
    
  }
}
