.gradient-name-financy-service {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.692));
}

.financing-red-box {
  padding: 5px 7px;
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: red;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: white;
}

.item-slider:hover {
  & .gradient-name-financy-service {
    height: 60%;
    background: linear-gradient(rgba(255, 0, 0, 0), rgb(255, 0, 0));
  }

  .box-arrow {
    background-color: rgb(255, 0, 0);
  }
}

.gradient-name-financy-service h3 {
  color: white;
  font-size: 1.3rem;
  width: 50%;
  margin-left: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}
